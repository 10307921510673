.navbar {
    background-color: #161616;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    top: 0;
    z-index: 999;

    .navbar-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        max-width: 1500px;
    }
    .navbar-logo {
        color: #f9ff9e;
        justify-self: start;
        margin-left: 20px;
        cursor: pointer;
        text-decoration: none;
        font-size: 2rem;
        display: flex;
        align-items: center;
      }
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
    margin-right: 2rem;
}

.nav-links {
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    font-size: 24px;
    color: #f9ff9e;
    font-family: 'Cinzel', serif;
    font-weight: 600;
    white-space: nowrap;
    transition: all 0.7s;

    &:hover {
        transition: all 0.2s ease-out;
        border-bottom: 1px solid #f9ff9e;
        color: #fff;
        text-shadow: 0 0 10px #fff,
            0 0 20px #fff,
            0 0 40px #fff,
            0 0 80px #fff,
    }
}

.nav-button {
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 24px;
    font-family: 'Cinzel', serif;
    color: #f9ff9e;
    white-space: nowrap;
    transition: all 0.7s;

    &:hover {
        border-bottom: 1px solid #f9ff9e;
        color: #fff;
        text-shadow: 0 0 10px #fff,
            0 0 20px #fff,
            0 0 40px #fff,
            0 0 80px #fff,
    }
}

.menu-icon {
    display: none;
}

@media screen and (max-width: 960px) {
    .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        margin: 0.5rem 0 0 1rem;
      }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 95vh;
        position: absolute;
        top: 1.9rem;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
        margin-right: 0;
    }

    .nav-menu.active {
        background: #242222;
        left: 0;
        transition: all 0.5s ease;
        z-index: 999;
        padding-inline-start: 0;
    }

    .nav-links,
    .nav-button {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;

        &:hover {
            background-color: #ffffff21;
        }
    }

    .menu-icon {
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        margin: 0 0 4rem 0;
        transform: translate(-100%, 60%);
        font-size: 1.5rem;
        cursor: pointer;
        color: #f9ff9e;
        transition: all 0.5s ease;
        &:hover {
            color: #fff;
        }
    }
    .nav-links {
        color: #fff;
        text-shadow: 0 0 10px #fff,
            0 0 20px #fff,
            0 0 40px #fff,
            0 0 80px #fff;
    
        &:hover {
            color: #f9ff9e;
        }
    }
}
