.container {
    background-color: rgb(126, 126, 126);
    width: 400px;
    height: 450px;
    perspective: 2000px;
    cursor: pointer;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    overflow: hidden;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 5px 5px 20px #666;
    animation: myfirst 2s;
    animation-direction: alternate;

    @keyframes myfirst {
        0% {
            top: 100%;
        }

        100% {
            top: 60%;
        }
    }

    .img,
    .details {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 20px;
        transition: all 1s;
    }

    .img {
        transform-origin: left;
        transform-style: preserve-3d;

        .img-front {
            position: absolute;
            backface-visibility: hidden;
            width: 100%;
            height: 100%;

            img {
                max-width: 100%;
                height: 100%;
                object-fit: cover;

            }
        }
    }

    .details {
        z-index: -1;
        background-color: #222222;

        p,
        h2,
        h4 {
            white-space: nowrap;
            color: #fff;
            margin: 1rem;
        }

        .item-icon {
            color: rgb(219, 219, 219);
            font-size: 3rem;
            text-align: center;
        }
    }

    &:hover .img {
        transform: rotateY(-130deg);
    }
}

@media only screen and (max-width: 600px) {
    .container {
        background-color: rgb(126, 126, 126);
        width: 280px;
        height: 320px;
        top: 75%;

        @keyframes myfirst {
            0% {
                top: 100%;
            }

            100% {
                top: 75%;
            }
        }

        .details {

            h2,
            h4 {
                font-size: 18px;
            }

        }
    }
}