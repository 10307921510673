.wrapper {
    padding: 0 10rem;
    margin-top: 55rem;
    background-color: rgb(22, 22, 22);
    .cards {
        padding: 0 6px;
        float: left;
        width: 24.99999%;

        .card {
            background-color: #000;
            margin: 0.5rem;
            overflow: hidden;
            border-radius: 25px;
            box-shadow: 5px 5px 20px rgb(24, 24, 24);

            &:hover {
                background-color: rgb(48, 48, 48);
            }

            img {
                width: 100%;
                height: 400px;
                object-fit: cover;
                box-shadow: 5px 5px 20px rgb(48, 48, 48);

                &:hover {
                    transform: scale(1.1);
                    transition: all 0.4s ease-in-out;
                }
            }

            h2 {
                padding: 0;
                text-align: center;
            }
            .fav-icon {
                z-index: 10;
                color: #fff;
                font-size: 20px;
                text-align: right;
                margin: 1rem;
            }
        }
    }
}

@media only screen and (max-width: 1600px) {
    .wrapper {
        padding: 0 2rem;
    }
}

@media only screen and (max-width: 1300px) {
    .wrapper {
        padding: 0;

        .cards {
            width: 49.99999%;
            margin: 6px 0;

            h2 {
                font-size: 20px;
            }
        }
    }
}

@media only screen and (max-width: 600px) {

    .wrapper {
        padding: 0;

        .cards {
            width: 100%;
        }
    }
}