.backdrop,
.backdrop-admin,
.backdrop-contact,
.backdrop-detail,
.backdrop-home {
    height: 100%;
    width: 100%;
    position: fixed;
    background: url(../../images/Dragon.jpg) no-repeat center/cover;
    filter: blur(4px);
    -webkit-filter: blur(4px);
}

.backdrop-admin {
    background: url(../../images/drag.jpg) no-repeat center/cover;
    z-index: -1;
}

.backdrop-contact {
    background: url(../../images/Contact.jpg) no-repeat center/cover;
    z-index: -1;
}

.backdrop-detail {
    background: url(../../images/detail.jpg) no-repeat center/cover;
    z-index: -1;
    filter: blur(0);
    -webkit-filter: blur(0);
    filter: grayscale(80%);
}

.backdrop-home {
    background: url(../../images/Daenerys.jpg) no-repeat center/cover;
    z-index: -1;
    filter: blur(0);
    -webkit-filter: blur(0);
}

.login,
.admin,
.contact {
    max-width: 100%;
    margin: 7rem 30rem;
    max-height: 100%;
    position: relative;
    background: url(../../images/Dragon.jpg) no-repeat center/cover;
    border-radius: 10px;
    box-shadow:
        0 12px 15px 0 rgba(0, 0, 0, 0.692),
        0 17px 50px 0 rgba(0, 0, 0, 479);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: mymove 3s;

    @keyframes mymove {
        from {
            top: 500px;
        }

        to {
            top: 0px;
        }
    }

    .login-page,
    .admin-page,
    .contact-page {
        width: 100%;
        height: 100%;
        padding: 1rem;
        background: rgba(29, 29, 28, 0.171);

        .login-heading {
            width: 100%;
            padding: 15px;
            display: flex;
            justify-content: center;
        }

        .login-icon,
        .admin-icon,
        .contact-icon {
            color: rgb(219, 219, 219);
            font-size: 5rem;
            text-align: center;
        }

        .login-form {
            min-height: 345px;
            position: relative;

            .error {
                background-color: red;
                padding: 15px 20px;
                color: #fff;
                border-radius: 25px;
                outline: none;
                margin: 1rem;
            }

            .success {
                background-color: rgb(0, 255, 55);
                padding: 15px 20px;
                color: #000;
                border-radius: 25px;
                outline: none;
                margin: 1rem;
            }

            .group {
                margin: 1rem;

                .label {
                    color: rgb(0, 0, 0);
                    display: block;
                    margin-bottom: 1rem;
                    font-size: 18px;
                }

                input,
                button,
                textarea,
                p {
                    width: 100%;
                    display: block;
                    padding: 15px 20px;
                    border: none;
                    border-radius: 25px;
                    outline: none;
                    background: rgba(255, 255, 255, 0.363);

                    &::placeholder {
                        color: rgb(255, 255, 255);
                    }
                }

                .button {
                    color: #f9ff9e;
                    font-size: 16px;
                    font-weight: 600;
                    width: 70%;
                    margin: auto;
                    background-color: #161616d7;
                    cursor: pointer;
                    transition: all 0.3s;
                    padding: 15px 20px;
                    border: none;
                    border-radius: 25px;
                    outline: none;
                    
                    &:hover {
                        color: #000;
                        background-color: #f9ff9e;
                    }
                }

                p {
                    color: rgb(255, 0, 0);
                    background-color: rgba(255, 255, 255, 0.575);
                    font-weight: 600;
                    letter-spacing: 1px;
                }

                .password-div {
                    display: flex;
                    position: relative;

                    .showPass {
                        font-size: 15px;
                        position: absolute;
                        top: 32%;
                        right: 6%;
                        color: rgb(63, 63, 63);
                    }
                }
            }
        }
    }
}

.hr {
    height: 2px;
    margin: 2rem 1rem;
    background: rgba(255, 255, 255, .2);
}

.contact {
    background: url(../../images/Contact.jpg) no-repeat center/cover;

    &__group {
        h3 {
            color: #fff;
        }
    }

    .contact-label {
        color: #fff;
        display: block;
        margin-bottom: 1rem;
        font-size: 18px;
    }

    .css-yk16xz-control {
        display: flex !important;

        .css-1wa3eu0-placeholder {
            color: #fff;
        }
    }

    .css-yk16xz-control,
    .css-1pahdxg-control {
        width: 100%;
        border: none;
        border-radius: 25px;
        outline: none;
        background: rgba(255, 255, 255, 0.363);
        padding: 5px 10px;
    }

    .css-1hb7zxy-IndicatorsContainer {
        svg {
            color: #fff;
        }
    }
}

@media (max-width: 1400px) {

    .login,
    .admin,
    .contact {
        margin: 1rem 10rem;
    }
}

@media (max-width: 700px) {

    .login,
    .admin,
    .contact {
        margin: 1rem 1rem;
    }
}