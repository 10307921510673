@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700;800&family=EB+Garamond:wght@400;500&family=Nanum+Gothic&display=swap');

* {
    box-sizing: border-box;
}

html,
body {
    padding: 0;
    margin: 0;
    font-family: 'Nanum Gothic', sans-serif;
    font-family: 'EB Garamond', serif;
    
}
html {
    background-color: rgb(22, 22, 22);
}
a {
    color: inherit;
    text-decoration: none;
}

h1, h2, h3, h4 {
    font-family: 'Cinzel', serif;
    text-align: center;
    margin-top: 2rem;  
}
h1 {
    font-size: 50px;
    color: #ffee37;
}
h2 {
    color: rgb(236, 236, 236);
}
h3 {
    font-size: 35px;
    color: #ffee37;
    text-align: center;
}
h2, h4 {
    text-align: left;
    color: #ffee37;
}
@media (max-width: 700px) {
    h1 {
        font-size: 30px;
    }
}

.heart {
    background-color: transparent;
    color: #fff;
    border: none;
    outline: none;
}